import React from 'react'
import HTMLContent from './HTMLContent'

const Section = (props) => {
  return(
    <div className="cv__section">
      <h2 className="cv__title">{props.section.section}</h2>
      <ul>
        {props.section.items.map((item, i) => (
          <li key={i} className="cv__text--item">
						<div className="tag">
							{item.tag.map((tag, t) => <span key={t} className={tag}></span>)}
						</div>
            { item.date &&
              <sub>{item.date}</sub>
            }
            {(item.title || item.type) &&
              <span><strong>{item.title}</strong><sub>{item.type}</sub></span>
            }
            <HTMLContent content={item.description} />
          </li>
        ))}
      </ul>
    </div>
  )
}

const Bio = (props) => {
  return(
      <HTMLContent className="cv__bio" content={props.content} />
  )
}

class Cv extends React.Component {
  render () {
    let id = 0;
    return (
      <div>
        <Bio content={this.props.bio}/>
        {this.props.data.map((section, i) => <Section key={id++} section={section} />)}
      </div>
    )
  }
}

export default Cv;
