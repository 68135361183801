import React from 'react'

class  WorkItem extends React.Component {
  render () {
    const {title, link, date, short_description} = this.props.item
    return(
      <div className="row">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {title}<sup>{short_description} {date}</sup>
        </a>
      </div>
    )
  }
}

export default WorkItem;
