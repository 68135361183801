import React from 'react'
// import PropTypes from 'prop-types'
import '../style/accordion.scss'

class Accordion extends React.Component {


  toggleAccordion(id) {
    this.setState({
      items: this.state.items.map( item => {
        if (item.id !== id && item.active!== true) return item
        item.active = !item.active
        return item
      })
    })
  }

  render () {
    return(
      <div className="accordion">
          {this.props.content}
      </div>
    )
  }
}

export default Accordion;
