import React from 'react'

class Lighting extends React.Component {
	render () {
		return(
			<div className="soon">Lighting Designer</div>
		)
	}
}

export default Lighting;
