import React from 'react'
// import '../performing.scss'
import axios from 'axios'
import WorkItem from './WorkItem'

class Performing extends React.Component {

	constructor() {
    super()
    this.state = {
      item: "",
    }
  }

	componentDidMount () {
    axios.get('wp/wp-json/wp/v2/pages/' + this.props.id )
      .then(res => {
				console.log(res.data.id)
        this.setState({
          item:
            {
              id: this.props.id,
              title: 'Selected Projects',
              content: res.data.acf.works.map((w,i) => <WorkItem key={i} item={w} />),
            }
        })
      }
    )
  }


  render () {
    return(
				<>
					<div className="soon">Performer</div>
        	{this.state.item.content}
				</>
    )
  }
}

export default Performing;
