import React from 'react'

class HMLTContent extends React.Component {
  render () {
    return (
      <div className={this.props.className} dangerouslySetInnerHTML={{__html: this.props.content}}></div>
    )
  }
}

export default HMLTContent;
