import React from 'react'
import axios from 'axios'
import Cv from './Cv.js'


const File = (props) => {
  if (!props.file) return false
  return(
    <div className="file">
      <a href={props.file.link} target="_blank" rel="noopener noreferrer">Download PDF</a>
    </div>
  )

}

class CvPage extends React.Component {

	constructor() {
    super()
    this.state = {
      item: '',
    }
  }

	componentDidMount () {
    axios.get('wp/wp-json/wp/v2/pages/11')
      .then(res => {
        // console.log(res.data);
        this.setState({
          item: {
              id: 0,
              title: res.data.title.rendered,
              content: <Cv data={res.data.acf.text} bio={res.data.acf.contact_info}/>,
              file: res.data.acf.cv_file
            }
        })
      }
    )
  }

  render () {
    return(
				<>
					<div className="soon">{this.state.item.title ? this.state.item.title : ''}</div>
						<File file={this.state.item.file} />
	          {this.state.item.content}
				</>
    )
  }
}

export default CvPage;
