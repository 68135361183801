import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './style/App.scss';
import Header from './components/Header'
import Home from './components/Home'
import CvPage from './components/CvPage'
import Dev from './components/Dev'
import NotFound from './components/NotFound'
import Lighting from './components/Lighting'
import Performing from './components/Performing'
import ReactGA from 'react-ga';

const trackingId = "UA-20324432-5";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <main className="App">
			<Router>
	      <Header />
				<Switch>
					<Route path="/dev">
						<Dev id="7"/>
					</Route>
					<Route path="/performing">
						<Performing id="56"/>
					</Route>
					<Route path="/lighting">
						<Lighting id ="9"/>
					</Route>
					<Route path="/cv">
						<CvPage />
					</Route>
					<Route exact path="/">
						<Home id="2" />
					</Route>
					<Route >
						<NotFound />
					</Route>
				</Switch>
			</Router>
    </main>
  );
}

export default App;
