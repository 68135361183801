import React from 'react'
import axios from 'axios'
import Accordion from './Accordion.js'
import ProjectItem from './ProjectItem'

class Dev extends React.Component {

	constructor() {
    super()
    this.state = {}
  }

	componentDidMount () {
    axios.get('wp/wp-json/wp/v2/pages/' + this.props.id )
      .then(res => {
        this.setState({
          id: this.props.id,
          title: res.data.title.rendered,
          content: res.data.acf.works.map((w,i) => <ProjectItem key={i} item={w} toggleItem={() => this.toggleAccordion(w.id)}/>),
        })
      }
    )
  }

	render () {
		return(
			<>
				<div className="soon">Creative Dev</div>
				<Accordion content={this.state.content} />
			</>
		)
	}
}

export default Dev;
