import React from 'react'
import { Link } from "react-router-dom";
import '../style/notfound.scss';

class NotFound extends React.Component {
	render () {
		return(
				<>
					<div className="soon">404</div>
					<Link to="/" className="home__link">back</Link>
				</>
		)
	}
}

export default NotFound;
