import React from 'react'
import {
//   Switch,
//   Route,
	Link,
} from "react-router-dom";
import '../style/header.scss'

// import PropTypes from 'prop-types'

class Header extends React.Component {
  render () {
    return (
      <header className="header">
				<Link to="/">
					<div className="logo">
	        	<h1 className="title">Dimitris Baltas</h1>
	        	<h2 className="role">creative dev | performer | lighting designer</h2>
					</div>
				</Link>
      </header>
    )
  }
}

export default Header ;
