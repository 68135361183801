import React from 'react'
import axios from 'axios'
import HTMLContent from './HTMLContent'


let soon;

const Text = (props) => {
  return(
      <HTMLContent className="text" content={props.content} />
  )
}

const Image = (props) => {
  return(
			<div className="gallery__image">
      	<img src={props.image.sizes.large} alt=""/>
			</div>
  )
}

class ProjectItem extends React.Component {
	constructor() {
		super();
		this.state = {
			active: false,
		};
	}

	updateType(type) {
		soon.innerHTML = type
	}

	componentDidMount () {
		soon = document.querySelector('.soon');
    axios.get('wp/wp-json/wp/v2/posts/' + this.props.item.ID )
      .then(res => {
        this.setState({
          id: this.props.item.ID,
          title: res.data.title.rendered,
          content: res.data.content.rendered,
					gallery: res.data.acf.images ? res.data.acf.images.map(image => <Image key={image.ID} image={image} />) : false,
					tags: res.data.acf.tag,
					type: res.data.acf.type,
					link: res.data.acf.link,
					date: res.data.acf.date,
					privacy: res.data.acf.privacy.length ? true : false
        })
      }
    )
  }

	componentDidUpdate() {
			const rows = document.querySelectorAll('[data-type]');

			if (rows) {
				[].forEach.call(rows, row => {
					row.addEventListener('mouseenter', () => {
						this.updateType(row.getAttribute('data-type'));
					})
					row.addEventListener('mouseleave', () => {
						this.updateType('Projects');
					})
				})
			}
	}


  render () {
    return (
      <div
				className={`accordion__row  ${this.state.active ? 'open' : ''} ${this.state.tags ? this.state.tags.join(' ') : ''}`}
				data-type={this.state.type}
			>
			<div className="tags">
			{this.state.tags &&
				this.state.tags.map((tag, t) => <div key={t} className={`tag ${tag}`}/>)}
				</div>
				{ this.state.link && !this.state.privacy
					? <a href={this.state.link} target="_blank" rel="noopener noreferrer" className="accordion__tab">{this.state.title}</a>
					: (this.state.content || this.state.gallery)
						? <>
								<button
				          className="accordion__tab"
				          onClick={() => this.setState({active: !this.state.active}) }
				        >
				          <Text content={this.state.title} />
				        </button>
				        <div className="accordion__content" >
									<Text content={ this.state.content }/>

									{this.state.gallery &&
										<div className="gallery">
											{this.state.gallery}
										</div>
									}
				        </div>
							</>
						: <div className="accordion__tab">{this.state.title}</div>
				}

      </div>
    )
  }
}

export default ProjectItem;
