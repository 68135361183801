import React from 'react'
import { Link }  from 'react-router-dom'
import axios from 'axios'
import Accordion from './Accordion.js'
import ProjectItem from './ProjectItem'
import '../style/intro.scss'
import '../style/accordion.scss'

class Home extends React.Component {

	constructor() {
    super()
    this.state = {}
		this.link = {}
  }

	componentDidMount () {
    axios.get('wp/wp-json/wp/v2/pages/' + this.props.id )
      .then(res => {
				this.link = {
					title: res.data.acf.link_title,
					id: res.data.acf.link.ID
				}

        this.setState({
          id: this.props.id,
          title: res.data.title.rendered,
          content: res.data.acf.works.map(w => <ProjectItem key={w.ID} item={w} toggleItem={() => this.toggleAccordion(w.ID)}/>),
        })
      }
    )
  }

	render () {
		return(
			<>
				<div className="soon">Projects</div>
				<div className="content">
					<Accordion content={this.state.content} />
					<div className="button__cv">
						<Link to={{
							pathname: '/CV',
							link:  this.link
						}} className="button__cv--link">{this.link.title}</Link>
					</div>
				</div>
			</>
		)
	}
}

export default Home;
